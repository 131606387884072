import './App.css';

function App() {
  return (
    <div className="App h-[100vh] max-w-[900px] m-auto drop-shadow-2xl border-x-2 border-gray-100/20">
      <div className='p-5 border-b-2 border-gray-100/20'>
        <a href="https://twitter.com/gradientfyi">
          <p className='w-fit cursor-pointer underline mb-1 text-right text-gray-100/60 text-sm'>twitter</p>
        </a>
        <p className='text-sm text-gray-100/60'>a lab focused on deep research.</p>
        <p className='text-4xl font-bold'>gradient.</p>
      </div>

      <div className='p-5 border-b-2 border-gray-100/20 border-b-2 border-gray-100/20'>
        <p className='w-fit text-sm'>
          <span className='text-gray-100/60'>27.8.2023</span>
        </p>
        <a target='_blank' href="https://twitter.com/gradientfyi/status/1695685910845968868">
          <p className='w-fit cursor-pointer underline'>hello world</p>
        </a>
      </div>

    </div>
  );
}

export default App;
